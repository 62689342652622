import React, { ReactChildren } from 'react'
import BackToTop from '../backToTop/backToTop'
import './textContent.sass'
interface TextContentProps {
  children: ReactChildren
}

const TextContent = ({ children }: TextContentProps) => (
  <div className="text-content">
    <div className="text-content__wrapper">
      {children}
      <BackToTop />
    </div>
  </div>
)

export default TextContent
