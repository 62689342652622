import React from 'react'
import ChevronUp from '../../assets/images/chevron_top.inline.svg'

import './backToTop.sass'

const BackToTop = () => {
  const onClick = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="back-to-top" onClick={onClick}>
      <ChevronUp />
    </div>
  )
}

export default BackToTop
