import React, { useContext } from 'react'
import { GeoContext } from '../state/geo'
import Container from '../components/container/container'
import TextContent from '../components/textContent/textContent'
import Layout from '../layout/layout'
import BackButton from '../components/backButton/backButton'

const PrivacyPage = () => {
  const geo = useContext(GeoContext)
  return (
    <Layout>
      <Container>
        <BackButton />
        <TextContent>
          <h1>Privacy statement</h1>
          <ol>
            <li>
              <a href="#section1">General information</a>
            </li>
            <li>
              <a href="#section2">
                What kind of data is collected and how is it used?
              </a>
            </li>
            <li>
              <a href="#section3">Which cookies are used?</a>
            </li>
            <li>
              <a href="#section4">Social media plugins</a>
            </li>
            <li>
              <a href="#section5">
                Is other personal data collected and processed?
              </a>
            </li>
            <li>
              <a href="#section6">
                Will my data be transferred to third parties, e. g. authorities?
              </a>
            </li>
            <li>
              <a href="#section7">How long will my data be stored?</a>
            </li>
            <li>
              <a href="#section8">
                Do I have a right to information and rectification of my stored
                data? What other rights do I have with regard to my stored data?
              </a>
            </li>
            <li>
              <a href="#section9">
                Can I withdraw my consent to the use of my data?
              </a>
            </li>
            <li>
              <a href="#section10">
                {' '}
                Who is my contact person if I have questions about data
                protection?
              </a>
            </li>
            <li>
              <a href="#section11">
                {' '}
                How long is this data privacy declaration valid?{' '}
              </a>
            </li>
          </ol>
          <h2 id="section1">1. General information</h2>
          <p>
            Thank you for your interest in the website(s) of Merck KGaA,
            Frankfurter Strasse 250, 64293 Darmstadt, Germany, hereinafter
            referred to as “MDA”. We take data protection and privacy issues
            very seriously and comply with the applicable national and European
            data protection regulations. Therefore, we would like to inform you
            with this declaration about data protection measures and which data
            we may store and how we use this data.
          </p>
          <h2 id="section2">
            2. What kind of data is collected and how is it used?
          </h2>
          <h3>2.1 Data collection by this website</h3>
          <p>
            Whenever a user accesses the website, the user’s internet browser
            automatically transfers the following data to Merck’s web server for
            technical reasons:
          </p>
          <ul>
            <li>
              <strong>IP address of the requesting computer</strong>
            </li>
            <li>
              <strong>date and time of access</strong>
            </li>
            <li>
              <strong>name and URL of the retrieved file</strong>
            </li>
            <li>
              <strong>transferred data volume</strong>
            </li>
            <li>
              <strong>
                access status (file transferred, file not found etc.)
              </strong>
            </li>
            <li>
              <strong>
                identification data of the browser and operating system used
              </strong>
            </li>
            <li>
              <strong>name of the provider of user's internet access</strong>
            </li>
            <li>
              <strong>website from which access is made</strong>
            </li>
          </ul>
          <p>
            This data is collected, processed and used for the purpose of
            enabling the use of the website (connection setup), system security
            and technical administration of the network infrastructure. A
            comparison with other databases or a transfer to third parties, also
            in excerpts, does not take place. The legal basis for processing is
            Art. 6 para. 1 b GDPR. The data collected as part of the voluntary
            entries under “Contacts & Service” will be used and processed
            exclusively for the purpose of processing the enquiry and to carry
            out the services you may have requested.
          </p>
          <h3>2.2 Use of Google Analytics</h3>
          <p>
            This website uses Google Analytics, a web analytics service provided
            by Google Inc. (“Google”). Google Analytics uses “cookies”, which
            are text files placed on your computer, to help the website analyze
            how users use the site. The information generated by the cookie
            about your use of this website is usually transferred to a Google
            server in the USA and stored there. However, if you activate the IP
            anonymization on this website, your IP address will be shortened
            previously by Google within Member States of the European Union or
            in other contracting states of the Agreement on the European
            Economic Area. Only in exceptional cases the full IP address is
            transferred to a Google server in the USA and shortened there. On
            behalf of the operator of this website, Google will use this
            information to evaluate your use of the website, to compile reports
            on website activity and to provide the website operator with further
            services related to website and internet use. The IP address
            transmitted by your browser within the scope of Google Analytics
            will not be aggregated with other Google data. You may refuse the
            use of cookies by selecting the appropriate settings on your
            browser, however please note that if you do this you may not be able
            to use the full functionality of this website. You can also prevent
            Google from collecting the data generated by the cookie and relating
            to your use of the website (including your IP address) and from
            processing this data by downloading and installing the browser
            plug-in available under the following link:{' '}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            . You can prevent Google Analytics from collecting data by clicking
            on the following link. An opt-out cookie is set that prevents future
            collection of your data when you visit this website.
          </p>
          <p>
            For more information on terms of use and privacy, please visit{' '}
            <a href="http://www.google.com/analytics/terms/de.html">
              http://www.google.com/analytics/terms/de.html
            </a>{' '}
            or{' '}
            <a href="https://www.google.de/intl/de/policies/">
              https://www.google.de/intl/de/policies/
            </a>
            . Please note that on the website Google Analytics has been extended
            by the code “anonymizeIp” in order to guarantee an anonymous
            registration of IP addresses (so-called IP masking).
          </p>
          <p>
            The legal basis for processing is Art. 6 para. 1 f GDPR, whereby
            Merck KGaA, Darmstadt, Germany’s authorization arises from the fact
            that, on the one hand,Merck KGaA, Darmstadt, Germany has an interest
            in evaluating the website data for purposes of website optimization
            and, on the other hand, a concerned person can reasonably foresee at
            the time when the personal data is collected and in view of the
            circumstances under which it is carried out (in particular the
            above-mentioned measures) that it will possibly be processed for
            this purpose.
          </p>
          <h2 id="section3">3. Which cookies are used?</h2>
          <p>
            We use cookies on our website. If you do not want to take advantage
            of our cookies, you can find out in the help function of your
            browser how to set your browser to prevent it from accepting new
            cookies or deleting existing cookies. There you will also learn how
            to block your browser for all new cookies or which settings you have
            to make in order to receive a notification of new cookies.
          </p>
          <p>
            The legal basis for processing is Art. 6 para. 1 f GDPR, whereby
            Merck KGaA, Darmstadt, Germany’s authorization arises from the fact
            that, on the one hand,Merck KGaA, Darmstadt, Germany has an interest
            in evaluating the website data for purposes of website optimization
            and, on the other hand, a concerned person can reasonably foresee at
            the time when the personal data is collected and in view of the
            circumstances under which it is carried out (in particular the
            above-mentioned measures) that it will possibly be processed for
            this purpose.
          </p>
          <p>
            The cookies that we currently use on this website are listed in the
            cookie list that is available here:
          </p>
          <table>
            <thead>
              <tr>
                <th>Identifier</th>
                <th>Type</th>
                <th>Lifespan</th>
                <th>Content</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>Web Storage</td>
                <td>2 years</td>
                <td>IP--Address, usage behavior</td>
                <td>Used to distinguish users</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>Web Storage</td>
                <td>24 hours</td>
                <td>IP--Address, usage behavior</td>
                <td>Used to distinguish users</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>Web Storage</td>
                <td>1 minute</td>
                <td>IP--Address, usage behavior</td>
                <td>Used to throttle request rate</td>
              </tr>
              <tr>
                <td>gac</td>
                <td>Web Storage</td>
                <td>90 days</td>
                <td>IP--Address, usage behavior</td>
                <td>Contains campaign related information for the user</td>
              </tr>
              <tr>
                <td>uxt_noticetaken</td>
                <td>Web Storage</td>
                <td>1 year</td>
                <td>Boolean</td>
                <td>Used to display cookie notice</td>
              </tr>
              <tr>
                <td>uxt_splashseen</td>
                <td>Web Storage</td>
                <td>99 years</td>
                <td>Boolean</td>
                <td>Used to display splash screen</td>
              </tr>
            </tbody>
          </table>
          <p>
            The legal basis for processing is Art. 6 para. 1 f GDPR, whereby
            Merck KGaA, Darmstadt, Germany’s authorization arises from the fact
            that, on the one hand, Merck KGaA, Darmstadt, Germany has an
            interest in evaluating the website data for purposes of website
            optimization and, on the other hand, a concerned person can
            reasonably foresee at the time when the personal data is collected
            and in view of the circumstances under which it is carried out (in
            particular the above-mentioned measures) that it will possibly be
            processed for this purpose.
          </p>
          <h2 id="section4">4. Social media plugins</h2>
          <p>We do not use social media plugins on this website.</p>
          <h2 id="section5">
            5. Is other personal data collected and processed?
          </h2>
          <p>
            We collect and process your personal data only if you request
            certain services and we need your data for this purpose or if you
            have voluntarily given us your express consent. The legal basis for
            processing is Art. 6 Para. 1 b GDPR and Art. 6 Para. 1 a GDPR. You
            can do this, for example, by completing a registration form or
            sending us an email, ordering products or services, submitting
            inquiries to us, requesting materials or registering. Unless
            otherwise required by law, we will only use your personal data for
            the purposes for which you have given your consent. For special
            services such as newsletters, sweepstakes, etc. the respective
            special data protection provisions apply.
          </p>
          <h2 id="section6">
            6. Will my data be transferred to third parties, e. g. authorities?
          </h2>
          <p>
            At MDA, those bodies within MDA receive your data that are required
            to fulfill our contractual and legal obligations. Some data must be
            disclosed under strict contractual and legal requirements:
          </p>
          <p>
            <strong>Due to legal obligation:</strong>
          </p>
          <ul className="spread-list">
            <li>
              <strong>
                In certain cases, we are required by law to transfer data to a
                requesting public authority. Upon submission of a court order,
                we are obliged pursuant to § 101 of the German Copyright Act to
                provide owners of copyright and ancillary copyrights with
                information about customers who are alleged to have offered
                copyright-protected works on internet file-sharing sites. In
                these cases, our information contains the user ID of an IP
                address allocated at the time requested and, if known, the name
                and address of the customer.
              </strong>
            </li>

            <li>
              <strong>
                In other respects, personal data will only be transferred to
                state institutions and public authorities within the framework
                of mandatory national legal provisions or if disclosure is
                necessary in the event of attacks on the network infrastructure
                for legal or criminal prosecution. The legal basis for
                processing is Art. 6 Para. 1 c GDPR or § 24 Para. 2 No. 1 German
                Federal Data Protection Act.
              </strong>
            </li>
            <li>
              <strong>
                To external service providers for data processing:
              </strong>
            </li>

            <li>
              <strong>
                When service providers get access to our customers’ personal
                data, this usually takes place in the course of so-called order
                processing of personal data. This is expressly provided for by
                law. In this case, MDA remains responsible for the protection of
                your data – in addition, the processor may also be responsible.
                The service provider works strictly in accordance with our
                instructions, which we ensure by means of strict contractual
                regulations, technical and organizational measures and
                supplementary controls.
              </strong>
            </li>

            <li>
              <strong>
                MDA works with service suppliers as processors. These are MDA
                Group companies and service providers for IT services (e. g. for
                technical-administrative tasks and for usage analysis),
                telecommunications, consulting and advisory services as well as
                sales and marketing.
              </strong>
            </li>

            <li>
              <strong>
                The data protection regulations for instruction-bound order
                processing of personal data are complied with.
              </strong>
            </li>
          </ul>
          <p>
            <strong>To MDA Group companies:</strong>
          </p>
          <ul>
            <li>
              <strong>
                MDA may transfer your personal data to MDA Group companies in
                order to carry out a business relationship with you or for the
                purposes of legitimate interests.
              </strong>
            </li>

            <li>
              <strong>
                If data are transferred abroad, they are based within the EU or
                the EEA or in a country which, according to the decision of the
                EU Commission, has an appropriate level of data protection. In
                the case of data transfers to MDA Group companies domiciled in
                other countries, MDA ensures by way of guarantees that the
                data-importing MDA Group company has been obligated to an
                appropriate level of data protection.
              </strong>
            </li>
          </ul>
          <p>
            Beyond this, we do not transfer data to third parties unless you
            have given your express consent, the transfer is obviously necessary
            for the provision of an offer or service requested by you or this is
            provided for by law. We also do not intend to transfer your data
            beyond this to a third country or international organization.
          </p>
          <h2 id="section7">7. How long will my data be stored?</h2>
          <p>
            We store data for as long as it is legally necessary or necessary
            for the provision of the service requested by you, or as long as it
            has been agreed upon in a declaration of consent.
          </p>
          <h2 id="section8">
            8. Do I have a right to information and rectification of my stored
            data? What other rights do I have with regard to my stored data?
          </h2>
          <p>
            You may at any time and free of charge request information about the
            scope, origin and recipients of the stored data as well as the
            purpose of the storage; in addition, you have the right to
            rectification, erasure or restriction of the processing of your data
            in accordance with data protection regulations, a right to object to
            the processing as well as a right to data portability. Please note
            that there is a right of appeal to a supervisory authority.
          </p>
          <h2 id="section9">
            9. Can I withdraw my consent to the use of my data?
          </h2>
          <p>
            You have the right to withdraw your consent to the use of your data
            at any time. Just send an email to{' '}
            <a href={`mailto:uxsd${geo?.url}`}>uxsd@{geo?.url}</a> or send a
            letter to the following address:
            <br />
            <strong>Merck KGaA</strong>
            <br />
            <strong>Group Data Protection Officer</strong>
            <br />
            <strong>Frankfurter Strasse 250</strong>
            <br />
            <strong>64293 Darmstadt</strong>
            <br />
            <strong>Germany</strong>
            <br /> The data processing performed on the basis of your consent is
            legal until the time of withdrawal.
          </p>
          <h2 id="section10">
            10. Who is my contact person if I have questions about data
            protection?
          </h2>
          <p>
            If you have any questions or comments, please feel free to contact
            the Group Data Protection Officer of Merck KGaA at any time: <br />
            <strong>Merck KGaA</strong>
            <br />
            <strong>Group Data Protection Officer</strong>
            <br />
            <strong>Frankfurter Strasse 250</strong>
            <br />
            <strong>64293 Darmstadt</strong>
            <br />
            <strong>Germany</strong>
            <br />
            <a href={`mailto:datenschutz@${geo?.url}`}>datenschutz@{geo?.url}</a>
          </p>
          <h2 id="section11">
            11. How long is this data privacy declaration valid?
          </h2>
          <p>
            This data privacy declaration is up-to-date and dates from
            (15.01.2019). We reserve the right to amend the data privacy
            declaration at any time with effect for the future, in particular to
            adapt it to a further development of the website or the
            implementation of new technologies.
          </p>
        </TextContent>
      </Container>
    </Layout>
  )
}

export default PrivacyPage
