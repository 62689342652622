import React from 'react'
import { navigate } from '@reach/router'
import ChevronLeft from '../../assets/images/chevron_left.inline.svg'
import './backButton.sass'

const BackButton = () => (
  <a onClick={() => navigate(-1)} className="back-button">
    <ChevronLeft className="back-button__icon" />
    Back
  </a>
)

export default BackButton
